import { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';

import { PasswordHideIcon } from '../Icons/PasswordHide';
import { PasswordShowIcon } from '../Icons/PasswordShow';
import { InputLabel } from './InputLabel';
import { Input } from './Input';

const TYPES = {
  PASSWORD: 'password',
  TEXT: 'text',
};

export const PasswordInput = ({ ...props }) => {
  const { label } = props;
  const [type, setType] = useState(TYPES.PASSWORD);
  const handleBtnClick = () =>
    setType(type === TYPES.PASSWORD ? TYPES.TEXT : TYPES.PASSWORD);

  return (
    <Input
      fullWidth
      FormHelperTextProps={{}}
      label={label ? <InputLabel label={label} /> : null}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleBtnClick}
              edge="end"
              size="small"
            >
              {type === TYPES.TEXT ? (
                <PasswordHideIcon />
              ) : (
                <PasswordShowIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      type={type}
      {...props}
    />
  );
};
