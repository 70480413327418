import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    checkbox: ({ contrastingLabel }: { contrastingLabel?: boolean }) => ({
      padding: 0,
      marginRight: 6,
      color: contrastingLabel
        ? theme.palette.grey[500]
        : theme.palette.grey[400],
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&.Mui-disabled': {
        position: 'relative',
        color: theme.palette.grey[400],
        '&::after': {
          content: "''",
          borderRadius: 2,
          position: 'absolute',
          background: theme.palette.grey[200],
          width: '58%',
          height: '58%',
        },
      },
    }),
    label: () => ({
      '&.Mui-disabled': {
        color: theme.palette.grey[400],
      },
    }),
    wrapper: {
      marginLeft: 0,
      marginRight: 12,
    },
  }),
  { index: 1 }
);
