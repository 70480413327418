import { TableCell as MaterialTableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './tableStyles';

export const FakeActionsCell = ({ actionsColumn, actions }) => {
  const styles = useStyles();

  const renderActionsCell = () => {
    if (actionsColumn) {
      return actionsColumn();
    }

    return actions.map(({ isHidden }, i) =>
      isHidden && isHidden() ? null : (
        <div key={i} className={styles.actionIconButton} />
      )
    );
  };

  return (
    <MaterialTableCell
      className={styles.actionsCell}
      classes={{ root: styles.tableCell, head: styles.tableCellHead }}
      component="div"
    >
      {renderActionsCell()}
    </MaterialTableCell>
  );
};

FakeActionsCell.propTypes = {
  actionsColumn: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
};

export default FakeActionsCell;
