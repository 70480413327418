import React from 'react';

export const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70005 5.9998C9.70005 5.83412 9.83436 5.6998 10 5.6998H16.5C16.6657 5.6998 16.8 5.83412 16.8 5.9998V15.9998C16.8 16.1655 16.6657 16.2998 16.5 16.2998H14.7V8.9998C14.7 8.06092 13.9389 7.2998 13 7.2998H9.70005V5.9998ZM8.30005 7.2998V5.9998C8.30005 5.06092 9.06116 4.2998 10 4.2998H16.5C17.4389 4.2998 18.2 5.06092 18.2 5.9998V15.9998C18.2 16.9387 17.4389 17.6998 16.5 17.6998H14.7V17.9998C14.7 18.9387 13.9389 19.6998 13 19.6998H6.00005C5.06117 19.6998 4.30005 18.9387 4.30005 17.9998V8.9998C4.30005 8.06092 5.06116 7.2998 6.00005 7.2998H8.30005ZM5.70005 8.9998C5.70005 8.83412 5.83436 8.6998 6.00005 8.6998H13C13.1657 8.6998 13.3 8.83412 13.3 8.9998V17.9998C13.3 18.1655 13.1657 18.2998 13 18.2998H6.00005C5.83436 18.2998 5.70005 18.1655 5.70005 17.9998V8.9998Z"
      fill="currentColor"
    />
  </svg>
);
