import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px 16px 8px 4px',
  },
  containedSizeSmall: {
    padding: '4px 12px 4px 4px',
  },
  startIcon: {
    marginLeft: 0,
    marginRight: 2,
  },
  endIcon: {
    marginLeft: 2,
    marginRight: 0,
  },
  outlined: {
    padding: '7px 15px 7px 3px',
  },
  outlinedSizeSmall: {
    padding: '3px 11px 3px 3px',
  },
  toggled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    '&$outlined': {
      border: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  progressWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'currentColor',
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[100],
    marginLeft: '12px',
  },
}));
