import { FC } from 'react';

import { InputLabel } from '../Inputs';
import { useStyles } from './SelectList.styles';

interface SelectListLabelProps {
  label: string;
  description?: string;
  showTooltip?: boolean;
  contrastingLabel?: boolean;
  required?: boolean;
}

const SelectListLabel: FC<SelectListLabelProps> = ({
  label,
  description,
  showTooltip,
  contrastingLabel,
  required,
}) => {
  const classes = useStyles({ contrastingLabel });

  return (
    <InputLabel
      label={label}
      tooltip={description}
      withLongLabelTooltip={showTooltip}
      className={classes.listLabel}
      required={required}
    />
  );
};

SelectListLabel.displayName = 'SelectListLabel';

export { SelectListLabel };
