import React from 'react';

export const RadioInactiveIcon = ({ disabled = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.8C16.3078 19.8 19.8 16.3078 19.8 12C19.8 7.69218 16.3078 4.2 12 4.2C7.69218 4.2 4.2 7.69218 4.2 12C4.2 16.3078 7.69218 19.8 12 19.8ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill="currentColor"
    />
    {disabled && <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="#EBEDF0"/>}
  </svg>
);
