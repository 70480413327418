import { forwardRef, memo } from 'react';
import { MenuItem, Checkbox, Typography } from '@material-ui/core';

import { SelectListOptionProps } from '@21vek/admin-front-components';

import { useStyles } from './SelectList.styles';
import {
  RadioActiveIcon,
  RadioInactiveIcon,
  CheckboxActiveIcon,
  CheckboxInactiveIcon,
} from '../Icons';

export const SelectListOption = forwardRef<
  HTMLLIElement,
  SelectListOptionProps
>(({ variant = 'checkbox', children, checked, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <MenuItem ref={ref} classes={{ root: classes.listItem }} {...rest}>
      <Checkbox
        color="primary"
        checked={checked}
        disabled={!!rest.disabled}
        classes={{ root: classes.option, disabled: classes.disabled }}
        checkedIcon={
          variant === 'radio' ? <RadioActiveIcon /> : <CheckboxActiveIcon />
        }
        icon={
          variant === 'radio' ? <RadioInactiveIcon disabled={!!rest.disabled} /> : <CheckboxInactiveIcon disabled={!!rest.disabled} />
        }
      />
      <Typography classes={{ noWrap: classes.noWrap }} noWrap variant="body2">
        {children}
      </Typography>
    </MenuItem>
  );
});

SelectListOption.displayName = 'SelectListOption';

export default memo(SelectListOption);
