import React, { forwardRef } from 'react';

import { InputBase } from './InputBase';
import { InputLabel } from './InputLabel';

interface InputPrimitiveProps {
  maxLength?: number;
  label?: string;
  required?: boolean;
  tooltip?: string;
  hideValidation?: boolean;
  fixedHelperText?: boolean;
  helperText?: string;
  withLongLabelTooltip?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const InputPrimitive = forwardRef<HTMLInputElement, InputPrimitiveProps>(
  (
    {
      label,
      tooltip,
      required,
      maxLength,
      hideValidation,
      helperText,
      fixedHelperText,
      withLongLabelTooltip,
      inputRef,
      ...props
    },
    ref
  ) => {
    let calcHelperText = !hideValidation ? helperText : undefined;

    if (!calcHelperText && fixedHelperText) calcHelperText = ' ';

    return (
      <InputBase
        label={
          label ? (
            <InputLabel
              label={label}
              tooltip={tooltip}
              inputRef={inputRef}
              required={required}
              withLongLabelTooltip={withLongLabelTooltip}
            />
          ) : null
        }
        maxLength={maxLength}
        helperText={calcHelperText}
        inputRef={inputRef}
        ref={ref}
        {...props}
      />
    );
  }
);
export default InputPrimitive;
