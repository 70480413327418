import React from 'react';

export const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 15V18H7V15H5V18C5 19.1 5.9 20 7 20H17C18.1 20 19 19.1 19 18V15H17ZM7 9L8.41 10.41L11 7.83V16H13V7.83L15.59 10.41L17 9L12 4L7 9Z"
      fill="currentColor"
    />
  </svg>
);
