import { FC, useState, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { ExpandProps, ButtonWithIcon } from '@21vek/admin-front-components';

import { ArrowDownIcon, ArrowUpIcon, HeavyCrossIcon } from '../Icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    width: '100%',
  },
  button: {
    backgroundColor: 'transparent !important',
    width: 'fit-content',
    paddingLeft: 0,
    fontSize: '16px',
    height: '24px',
    letterSpacing: 'initial',
    textTransform: 'initial',
    transition: 'none',
    justifyContent: 'space-between',
  },
  labelButton: {
    fontSize: '10px',
    fontWeight: 700,
    letterSpacing: 'initial',
    color: 'var(--white)',
    backgroundColor: theme.palette.primary.light,
    height: '18px',
    borderRadius: '18px',
    paddingLeft: '8px',
    paddingRight: '2px',
    marginLeft: 'auto',
    minWidth: '34px',
    maxWidth: 'fit-content',
    width: 'inherit',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  noBorder: {
    borderColor: 'transparent !important',
  },
  disabled: {
    opacity: 0.5,
    filter: 'grayscale(1)',
    pointerEvents: 'none',
  },
  fullWidthLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidthButton: {
    color: 'currentColor',
    width: '100%',
    padding: '0',
  },
}));

export const Expand: FC<ExpandProps> = ({
  children,
  title,
  className,
  wrapperClassName,
  labelButtonText,
  initial = true,
  fullWidth,
  disabled,
  isLast,
  prerenderChildren,
  onChange,
  onLabelButtonClick,
  open: openProps,
  ['data-entity']: { name: entityName, value: entityValue } = {},
}) => {
  const [openState, setOpenState] = useState(initial);
  const [renderChildren, setRenderChildren] = useState(
    initial || prerenderChildren
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const open = openProps ?? openState;

  const handleToggle = () => {
    onChange?.(!open);
    setOpenState(!open);
  };

  const handleLabelButtonClick = useCallback(
    (event) => {
      onLabelButtonClick?.(event);
      event.stopPropagation();
    },
    [onLabelButtonClick]
  );

  useEffect(() => {
    if (!initial && open) {
      setRenderChildren(true);
    }
  }, [open, initial, setRenderChildren]);

  useEffect(() => {
    if (open && isLast) {
      contentRef.current?.scrollIntoView();
    }
  }, [open, isLast]);

  return (
    <div
      className={clsx(
        classes.wrapper,
        { [classes.disabled]: disabled },
        className
      )}
      {...{ [`data-${entityName ?? ''}`]: entityValue }}
    >
      <ButtonWithIcon
        className={clsx(classes.button, { [classes.noBorder]: open })}
        iconAlign="end"
        icon={open && !!children ? <ArrowUpIcon /> : <ArrowDownIcon />}
        onClick={handleToggle}
        variant="text"
        disableElevation
        classes={
          fullWidth
            ? {
                label: classes.fullWidthLabel,
                root: classes.fullWidthButton,
              }
            : {}
        }
      >
        {title}
        {labelButtonText && (
          <ButtonWithIcon
            className={classes.labelButton}
            iconAlign="end"
            icon={<HeavyCrossIcon />}
            onClick={handleLabelButtonClick}
          >
            {labelButtonText}
          </ButtonWithIcon>
        )}
      </ButtonWithIcon>
      {!!children && (
        <div
          ref={contentRef}
          className={wrapperClassName}
          style={open ? undefined : { display: 'none' }}
        >
          {(renderChildren || isLast) && children}
        </div>
      )}
    </div>
  );
};

export default Expand;
