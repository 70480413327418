import clsx from 'clsx';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import TableBody from '../Table/TableBody';
import TableHead from '../Table/TableHead';
import FakeActionsCell from '../Table/FakeActionsCell';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';
import { useStyles } from '../Table/tableStyles';
import DraggableActionsCell, { tableActionModel } from './DraggableActionsCell';
import getClassName from '../../utils/getClassName';

const DraggableTable = ({
  className,
  columns,
  data,
  onRowClick,
  draggingState,
  actions,
  rowIcon,
  showIcon,
  handleDragEnd,
  handleDragStart,
  itemKey,
  getCellClass,
  getRowClass,
  headerClassName,
  droppableId,
  actionsColumn,
  actionsClassName,
}) => {
  const classes = useStyles();
  const hasActions = actions && actions.length > 0;

  const rowClickHandler = useCallback(
    (e) => {
      const { index } = e.currentTarget.dataset;

      if (onRowClick && index) {
        onRowClick(data[index], index);
      }
    },
    [onRowClick, data]
  );

  return (
    <div className={clsx(className)}>
      <TableHead>
        <TableRow className={clsx(headerClassName)}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              value={column.label}
              maxWidth={column.maxWidth}
              renderer={column.headRenderer}
            />
          ))}

          {hasActions ? <FakeActionsCell actions={actions} actionsColumn={actionsColumn} /> : null}
        </TableRow>
      </TableHead>

      <TableBody>
        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={(result) => handleDragEnd(result)}
        >
          <Droppable droppableId={`droppable-rows${droppableId || ''}`}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {data.map((row, i) => (
                  <Draggable
                    key={row[itemKey || 'index']}
                    draggableId={`droppable-rows-${
                      row[itemKey || 'index'] || i
                    }`}
                    index={i}
                  >
                    {(draggableProvided, snapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        className={clsx(classes.draggableRow, {
                          [classes.draggingRowWrap]: snapshot.isDragging,
                        })}
                      >
                        <TableRow
                          key={row.id}
                          data-index={i}
                          className={clsx(
                            {
                              [classes.tableRowDragging]: draggingState,
                            },
                            getClassName(getRowClass, row, i)
                          )}
                          {...(onRowClick ? { onClick: rowClickHandler } : {})}
                        >
                          {showIcon?.(row) && rowIcon}
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              value={row[column.id]}
                              row={row}
                              maxWidth={column.maxWidth}
                              renderer={column.cellRenderer}
                              className={clsx(
                                getClassName(getCellClass, row, i, column.id)
                              )}
                            />
                          ))}
                          {hasActions ? (
                            <DraggableActionsCell
                              draggableProvidedProps={draggableProvided}
                              draggableSnapshot={snapshot}
                              actions={actions}
                              row={row}
                              actionsClassName={actionsClassName}
                            />
                          ) : null}
                        </TableRow>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TableBody>
    </div>
  );
};

export const tableColumnModel = PropTypes.shape({
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  headRenderer: PropTypes.func,
  cellRenderer: PropTypes.func,
});

DraggableTable.propTypes = {
  /**
   *  Массив actions, структура: <br>
   *  tooltipText: PropTypes.string.isRequired, <br>
   *  icon: PropTypes.node.isRequired, <br>
   *  onClick: PropTypes.func, <br>
   *  color: PropTypes.oneOf( <br>['default', 'inherit', 'primary', 'secondary'] <br>), <br>
   *  isHidden: PropTypes.func, <br>
   *  key: PropTypes.string, <br>
   *  activeRow: PropTypes.objectOf(PropTypes.any), <br><br>
   *  В случаях когда у иконки key === "burger" - это будет иконка с помощью которой можно перетягивать строки
   */
  actions: PropTypes.arrayOf(tableActionModel).isRequired,
  /**
   *  Массив содержащий список колонок в формате {id, label},
   *  где id - ключ отображаемого значения из элемента передаваемого в массиве data,
   *  label - наименование колонки
   */
  columns: PropTypes.arrayOf(tableColumnModel).isRequired,
  /**
   *  Массив объектов содержащих данные из которых будет формироваться таблица
   */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   *  Класс применяемый к верхнему узлу таблицы,
   */
  className: PropTypes.string,
  /**
   *  Ключ из объекта в data который будет использоваться в качестве key при мапинге внутри таблицы, по умолчанию index
   */
  itemKey: PropTypes.string,
  /**
   *  Состояние перетягивания
   */
  draggingState: PropTypes.bool,
  /**
   *  Функция определяющая следует ли показывать иконку из rowIcon.<br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки <br><br>
   *
   *  <b>Внимание!</b> Если иконку отображать не для всех строк, то  строки будут сдвигаться, хорошо видно в примере.
   *  Для корректного отображения необходимо вставлять пустую иконку для тех мест, где не должно быть иконки.
   */
  showIcon: PropTypes.func,
  /**
   *  Иконка строки
   */
  rowIcon: PropTypes.func,
  /**
   *  Обработчик клика по строке,
   *  функция которая первым аргументом принимает элемент из массива data
   *  соответствующий строке по которой произошел клик
   */
  onRowClick: PropTypes.func,
  /**
   *  Обработчик окончания перетягивания
   */
  handleDragEnd: PropTypes.func,
  /**
   *  Обработчик начала перетягивания
   */
  handleDragStart: PropTypes.func,
  /**
   *  Класс навешиваемый на шапку <br>
   *  Перетягивание работает только за иконку с ключем "burger" из массива actions
   */
  headerClassName: PropTypes.string,
  /**
   *  Функция возвращающая класс который будет навешиваться на строку. <br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки
   */
  getRowClass: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  /**
   *  Функция возвращающая класс который будет навешиваться на ячейку. <br>
   *  аргументы: <br>
   *  rowData - данные по строке из массива data <br>
   *  index - порядковый номер строки
   *  key - ключ отвечающий по которому берется значение из rowData для этой ячейки
   */
  getCellClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
   *  Уникальный ключ дроп зоны, для страниц, на которых отображается по несколько гридов с d'n'd
   */
  droppableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   *  Колонка actions в хедере таблицы
   */
  actionsColumn: PropTypes.func,
  /**
   *  Класс на контейнер кнопок действий
   */
  actionsClassName: PropTypes.string,
};

DraggableTable.defaultProps = {
  handleDragStart: undefined,
  onRowClick: undefined,
  showIcon: undefined,
  rowIcon: undefined,
  draggingState: false,
  className: '',
  itemKey: '',
  headerClassName: '',
  getCellClass: '',
  getRowClass: '',
  actionsColumn: undefined,
  actionsClassName: '',
};

export default DraggableTable;
