import React from 'react';

export const QuestionIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2 6C11.2 8.87188 8.87188 11.2 6 11.2C3.12812 11.2 0.8 8.87188 0.8 6C0.8 3.12812 3.12812 0.8 6 0.8C8.87188 0.8 11.2 3.12812 11.2 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.55078 6.5C5.46484 6.70573 5.42188 6.98177 5.42188 7.32812H6.30859C6.3138 6.95312 6.40234 6.66797 6.57422 6.47266L7.05469 6C7.45052 5.58854 7.64844 5.17057 7.64844 4.74609C7.64844 4.26953 7.5013 3.89844 7.20703 3.63281C6.91536 3.36719 6.50781 3.23438 5.98438 3.23438C5.47656 3.23438 5.06771 3.36849 4.75781 3.63672C4.45052 3.90495 4.29427 4.26953 4.28906 4.73047H5.23828C5.23828 4.51432 5.30599 4.34375 5.44141 4.21875C5.57943 4.09115 5.76042 4.02734 5.98438 4.02734C6.21875 4.02734 6.39583 4.09245 6.51562 4.22266C6.63802 4.35026 6.69922 4.53646 6.69922 4.78125C6.69922 4.96615 6.64714 5.13802 6.54297 5.29688C6.47526 5.40104 6.3138 5.57682 6.05859 5.82422C5.80599 6.06901 5.63672 6.29427 5.55078 6.5ZM5.5 8.17188C5.40365 8.26562 5.35547 8.38932 5.35547 8.54297C5.35547 8.6862 5.40104 8.80599 5.49219 8.90234C5.58594 8.9987 5.71875 9.04688 5.89062 9.04688C6.0625 9.04688 6.19531 8.9987 6.28906 8.90234C6.38281 8.80599 6.42969 8.6862 6.42969 8.54297C6.42969 8.39453 6.38151 8.27214 6.28516 8.17578C6.1888 8.07682 6.05729 8.02734 5.89062 8.02734C5.72656 8.02734 5.59635 8.07552 5.5 8.17188Z"
      fill="currentColor"
    />
  </svg>
);
