import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    table: {
      width: '100%',
      ...theme.typography.body1,
    },
    tableHead: {
      display: 'block',
    },
    tableBody: {
      display: 'block',
    },
    tableRow: {
      display: 'flex',
      flexFlow: 'row nowrap',
      minHeight: '32px',
      padding: '0 24px',
      position: 'relative',
      '&$tableRowHover:hover': {
        backgroundColor: 'initial',
      },
      '&:first-child $tableCellBody': {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    draggableRow: {
      '&:first-child': {
        '& $tableRow': {
          '& $tableCellBody': {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          },
        },
      },
      '& $tableRow': {
        '&:first-child $tableCellBody': {
          borderTop: `unset`,
        },
      },
    },
    tableRowHead: {
      '& $tableCellTextContent': {
        ...theme.typography.body2,
      },
    },
    tableRowHover: {
      cursor: 'pointer',
      '&::after': {
        content: '""',
        opacity: 0,
        position: 'absolute',
        top: '-1px',
        bottom: '0',
        left: '0',
        right: '0',
        boxShadow: 'var(--box-shadow)',
        border: '2px solid white',
        pointerEvents: 'none',
      },
      '&:hover::after': {
        opacity: 1,
      },
    },
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: '8px',
      minWidth: 0,
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    tableCellHead: {
      color: theme.palette.text.secondary,
      border: 'none',
    },
    tableCellBody: {},
    tableListHeadCellText: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.5,
      marginBottom: 8,
    },
    tableCellBoldText: {
      fontWeight: 700,
    },
    tableCellTextContent: {},
    actionsCell: {
      flex: '0 0 0',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      color: theme.palette.grey[500],
      minWidth: 'auto',
      padding: 0,
    },
    actionIconButton: {
      width: '24px',
      height: '24px',
      marginLeft: '0.5rem',
      padding: 0,
      '&:first-child': {
        marginLeft: '0.25rem',
      },
    },
    actionIconButtonSubmitted: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[400],
    },
    emptyTable: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    emptyText: {
      marginTop: '20px',
    },
    draggingRowWrap: {
      backgroundColor: '#fff',
      boxShadow: 'var(--box-shadow)',
      '& $tableRow': {
        '& $tableCell': {
          borderTop: 'none',
          borderBottom: 'none',
        },
      },
    },
    tableRowDragging: {
      pointerEvents: 'none',
    },
    tableCellDragging: {
      border: 0,
    },
    loadingWrap: {
      position: 'absolute',
      backgroundColor: '#ffffff91',
      width: 'calc(100% - 240px - 80px)', // minus left menu and left and right content paddings
      zIndex: '999',
    },
    tableLink: {
      textDecoration: 'none', 
      color: 'inherit'
    }
  }),
  { index: 1 }
);
