import React from 'react';

export const CheckboxInactiveIcon = ({ disabled = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 5.2H6C5.55817 5.2 5.2 5.55817 5.2 6V18C5.2 18.4418 5.55817 18.8 6 18.8H18C18.4418 18.8 18.8 18.4418 18.8 18V6C18.8 5.55817 18.4418 5.2 18 5.2ZM6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6Z"
      fill="currentColor"
    />
    {disabled && <rect x="5" y="5" width="14" height="14" rx="1" fill="#EBEDF0"/>}
  </svg>
);