import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: (props) => props.borderRadius,
    margin: (props) => props.margin,
    maxHeight: (props) => props.maxHeight,
    height: (props) => props.height,
    width: (props) => props.width || '480px',
    maxWidth: (props) => props.maxWidth,
    position: 'relative',
  },
  titleRoot: {
    display: 'flex',
    padding: '24px 24px 8px',
  },
  titleRootChild: {
    display: 'flex',
  },
  descriptionRoot: {
    padding: '0 16px 0 24px',
    minHeight: '42px',
    marginRight: '4px',
    scrollbarGutter: 'stable right-edge',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'var(--grey-400)',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: 'var(--grey-500)',
    },
  },
  descriptionTextRoot: {
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
    marginBottom: '0',
  },
  actionsRoot: {
    padding: '24px',
  },
  closeIcon: {
    color: theme.palette.grey[400],
    padding: 0,
    position: 'absolute',
    top: '24px',
    right: '24px',
  },
}));
