// get rid of warnings in Strict mode, will be fixed in Material-UI v.5
// https://github.com/mui-org/material-ui/issues/13394#issuecomment-619266831
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import getScrollbarSize from '@material-ui/core/utils/getScrollbarSize';

function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
}

const addBodyClass = () => {
  if (window.document.body.style.paddingRight) {
    window.document.body.classList.add('scroll-block');
  }
};

const removeBodyClass = () =>
  window.document.body.classList.remove('scroll-block');

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        '08': 'rgba(0, 119, 204, 0.08)',
        light: '#0077CC',
        main: '#006BB8',
        dark: '#005FA3',
      },
      secondary: {
        '08': 'rgba(242, 76, 73, 0.08)',
        light: '#E62E6B',
        main: '#DB215F',
        dark: '#CC1F59',
      },
      grey: {
        100: '#F7F8FA',
        200: '#EBEDF0',
        300: '#E1E3E5',
        400: '#C4C7CC',
        500: '#95989D',
        800: '#3B3C40',
        900: '#212121',
      },
      green: {
        500: '#11A65B',
      },
      black: {
        900: '#1A1A1A',
        800: '#3B3C40',
        500: '#95989D',
        400: '#C4C7CC',
        300: '#E1E3E5',
        100: '#F7F8FA',
      },
      text: {
        primary: '#1A1A1A',
        secondary: '#95989D',
      },
    },
    typography: {
      button: {
        fontSize: '0.75rem',
        lineHeight: 'calc(16/12)',
        fontWeight: 500,
        letterSpacing: '0.1em',
      },
      body1: {
        fontSize: '0.875rem',
        lineHeight: 'calc(20/14)',
        fontWeight: 400,
        letterSpacing: 0,
      },
      body2: {
        fontSize: '0.75rem',
        lineHeight: 'calc(16/12)',
        fontWeight: 400,
        letterSpacing: 0,
      },
      h4: {
        fontSize: '1.5rem',
        lineHeight: 'calc(28/24)',
        fontWeight: 400,
        letterSpacing: 0,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: 0,
      },
      h6: {
        fontSize: '1.125rem',
        lineHeight: 'calc(26/18)',
        fontWeight: 500,
        letterSpacing: 0,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiPopover: {
        TransitionProps: {
          onEntering: addBodyClass,
          onExit: removeBodyClass,
        },
      },
      MuiDialog: {
        TransitionProps: {
          onEntering: addBodyClass,
          onExited: removeBodyClass,
        },
      },
    },
    sizes: {
      mainSidebar: {
        width: 240,
      },
      scrollbarSize: getScrollbarSize(),
    },
  },
  ruRU
);

theme.overrides = {
  MuiButton: {
    root: {
      padding: '12px 20px',
    },
    contained: {
      '&$disabled': {
        color: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[100],
      },
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    containedSecondary: {
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    outlined: {
      padding: '11px 19px',
      borderColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
      '&:active': {
        backgroundColor: theme.palette.grey[200],
      },
      '&$disabled': {
        border: `1px solid ${theme.palette.grey[300]}`,
        color: theme.palette.grey[400],
      },
    },
    outlinedPrimary: {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      '&:hover': {
        border: `1px solid ${theme.palette.primary.light}`,
        backgroundColor: hexToRGBA(theme.palette.primary.light, 0.08),
      },
      '&:active': {
        backgroundColor: hexToRGBA(theme.palette.primary.light, 0.16),
      },
    },
    outlinedSizeSmall: {
      fontSize: theme.typography.pxToRem(12),
      textTransform: 'capitalize',
      borderRadius: 2,
      letterSpacing: 'unset',
    },
    containedSizeSmall: {
      fontSize: theme.typography.pxToRem(12),
      textTransform: 'capitalize',
      borderRadius: 2,
      letterSpacing: 'unset',
    },
    textPrimary: {
      color: '#0077CC',
    },
    disabled: {},
  },
  MuiIconButton: {
    root: {
      backgroundColor: 'unset',
      transition: theme.transitions.create(['color', 'background-color'], {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&:active': {
        backgroundColor: 'unset',
      },
    },
    colorPrimary: {
      color: 'currentColor',
      '&:hover': {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.primary['08'],
      },
      '&:active': {
        color: theme.palette.primary.main,
      },
    },
    colorSecondary: {
      color: 'currentColor',
      '&:hover': {
        color: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary['08'],
      },
      '&:active': {
        color: theme.palette.secondary.main,
      },
    },
    edgeEnd: {
      borderRadius: 0,
      color: theme.palette.grey[400],
      '&:hover': {
        color: theme.palette.grey[500],
      },
    },
    sizeSmall: {
      padding: 0,
    },
  },
  MuiTypography: {
    noWrap: {
      '&::after': {
        content: '""',
        display: 'block',
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      borderRadius: '2px',
      fontWeight: 400,
      backgroundColor: theme.palette.grey[800],
      lineHeight: '1rem',
      maxHeight: 'calc(3rem + 7px)', // 3 строки высотой 1rem + верхний и нижний padding - 1px, т.к. видится верх слдующей строки
      overflow: 'hidden',
    },
    tooltipPlacementBottom: {
      margin: theme.spacing(0.5, 0),
      [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(0.5, 0),
      },
    },
  },
  MuiCircularProgress: {
    colorPrimary: {
      color: theme.palette.primary.light,
    },
  },
  MuiInputBase: {
    root: {
      '&.Mui-disabled': {
        color: theme.palette.grey[500],
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      '&$disabled $notchedOutline': {
        borderColor: theme.palette.grey[300],
      },
      '&$error $notchedOutline': {
        borderColor: theme.palette.secondary.light,
      },
      '&$focused $notchedOutline': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1,
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.grey[300],
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[500],
        background: theme.palette.grey[100],
      },
    },
    adornedEnd: {
      paddingRight: 12,
    },
    input: {
      padding: '11px 12px 9px',
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      height: '1.25rem',
    },
    notchedOutline: {
      borderColor: theme.palette.grey[300],
      borderRadius: '2px',
      borderWidth: 1,
      fontSize: '1rem',
      paddingLeft: '11px',
    },
    error: {},
    focused: {},
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      '& > span': {
        paddingRight: 2,
        paddingLeft: 2,
      },
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
    icon: {
      color: theme.palette.primary.light,
    },
  },
  MuiMenuItem: {
    root: {},
    gutters: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  MuiListItem: {
    root: {
      '&$focusVisible, &$button:hover': {
        backgroundColor: 'rgba(0, 119, 204, 0.08)',
        color: theme.palette.primary.light,
      },
    },
  },
  MuiListItemIcon: {
    root: {
      color: 'currentColor',
      minWidth: 28,
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      letterSpacing: 0,
      '&$error': {
        color: theme.palette.secondary.light,
      },
    },
    contained: {
      marginLeft: 12,
      marginRight: 12,
    },
    error: {},
  },
  MuiInputLabel: {
    root: {
      color: theme.palette.grey[800],
      fontSize: '0.8125rem',
      letterSpacing: 0,
      '&$focused': {
        color: theme.palette.grey[800],
      },
      '&$error': {
        color: theme.palette.grey[800],
      },
    },
    error: {},
    focused: {},
    asterisk: {
      color: theme.palette.secondary.main,
      '&$error': {
        color: theme.palette.secondary.main,
      },
    },
    outlined: {
      '&$shrink': {
        transform: 'translate(14px, -6px)',
      },
    },
  },
  MuiSnackbar: {
    anchorOriginBottomCenter: {
      [theme.breakpoints.up('sm')]: {
        left: `calc(50% + ${theme.sizes.mainSidebar.width / 2}px)`,
        'body.scroll-block &': {
          left: `calc(50% + ${theme.sizes.mainSidebar.width / 2}px - ${
            theme.sizes.scrollbarSize / 2
          }px)`,
        },
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      padding: '8px 16px 8px 4px',
      [theme.breakpoints.up('sm')]: {
        minWidth: 300,
      },
    },
    message: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: 4,
      },
    },
  },
  MuiSwitch: {
    switchBase: {
      '&:hover': {
        backgroundColor: 'initial !important',
      },
      '& $thumb': {
        backgroundColor: theme.palette.grey[400],
      },
      '&:hover $thumb': {
        backgroundColor: theme.palette.grey[500],
      },
      '&:hover + $track': {
        backgroundColor: theme.palette.grey[400],
      },
      '&$checked + $track': {
        opacity: 0.3,
      },
      '&$checked:hover + $track': {
        opacity: 0.4,
      },
      '&$disabled + $track': {
        opacity: 1,
        backgroundColor: `${theme.palette.grey[200]}!important`,
      },
      '&$disabled $thumb': {
        backgroundColor: `${theme.palette.grey[300]}!important`,
      },
    },
    track: {
      opacity: 1,
      backgroundColor: theme.palette.grey[300],
      transition: 'initial',
    },
    thumb: {
      boxShadow: 'none',
    },
    colorPrimary: {
      '&$checked $thumb': {
        backgroundColor: theme.palette.primary.light,
      },
      '&$checked:hover $thumb': {
        backgroundColor: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    colorSecondary: {
      '&$checked $thumb': {
        backgroundColor: theme.palette.secondary.light,
      },
      '&$checked:hover $thumb': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  MuiRadio: {
    root: {
      padding: 0,
    },
    checked: {},
    colorPrimary: {
      '&:hover': {
        backgroundColor: 'initial',
      },
      '&$checked': {
        color: theme.palette.primary.light,
        '&:hover': {
          backgroundColor: 'initial',
        },
      },
    },
    colorSecondary: {
      '&:hover': {
        backgroundColor: 'initial',
      },
      '&$checked': {
        '&:hover': {
          backgroundColor: 'initial',
        },
      },
    },
  },
  MuiPickersDay: {
    day: {
      '& p': {
        fontSize: '0.875rem',
      },
    },
  },
  MuiPickersCalendarHeader: {
    transitionContainer: {
      '& p': {
        fontSize: '1rem',
      },
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: 'unset',
      marginBottom: 12,
    },
  },
  MuiPickersBasePicker: {
    pickerView: {
      minHeight: 'unset',
    },
  },
  MuiPickersSlideTransition: {
    transitionContainer: {
      '& > *': {
        position: 'static',
      },
    },
    slideEnterActive: {
      transition: 'none',
    },
    'slideExitActiveLeft-left': {
      display: 'none',
    },
    'slideExitActiveLeft-right': {
      display: 'none',
    },
  },
  MuiAutocomplete: {
    option: {
      '&:hover, &[aria-selected="true"], &[data-focus="true"]': {
        backgroundColor: hexToRGBA(theme.palette.primary.light, 0.08),
        color: theme.palette.primary.light,
      },
    },
  },
  MuiPopover: {
    paper: {
      '&.MuiPaper-elevation8': {
        boxShadow:
          '0px 2px 6px rgba(8, 35, 48, 0.04), 0px 1px 4px rgba(8, 35, 48, 0.16);',
      },
    },
  },
};

export default theme;
