import MUITabs from '@material-ui/core/Tabs';
import MUITab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useTabsStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  indicator: {
    backgroundColor: theme.palette.primary.light,
    height: '3px',
  },
}));

const useTabStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 'auto',
    padding: 0,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    letterSpacing: 0,
    marginRight: 16,
    '&:focus': {
      color: theme.palette.primary.light,
    },
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  emptyTab: {
    display: 'none',
  },
}));

const Tabs = ({ activeTab, tabs, onChange, children }) => {
  const tabsClasses = useTabsStyles();
  const tabClasses = useTabStyles();

  return (
    <>
      <MUITabs
        value={activeTab}
        onChange={(e, newValue) => onChange(newValue)}
        indicatorColor="primary"
        textColor="primary"
        classes={{
          root: tabsClasses.root,
          indicator: tabsClasses.indicator,
        }}
      >
        {tabs.map((title) => (
          <MUITab
            key={title}
            label={title}
            classes={{
              root: !!title ? tabClasses.root : tabClasses.emptyTab,
            }}
          />
        ))}
      </MUITabs>

      <div className="Admin-Tabs_TabContent">{children}</div>
    </>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  activeTab: 0,
};

export default Tabs;
