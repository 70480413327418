import clsx from 'clsx';
import React from 'react';
import {
  IconButton,
  TableCell as MaterialTableCell,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from '../Table/tableStyles';

const DraggableActionsCell = ({
  actions,
  row,
  draggableSnapshot,
  draggableProvidedProps,
  actionsClassName,
}) => {
  const styles = useStyles();
  return (
    <MaterialTableCell
      className={clsx(styles.actionsCell, actionsClassName, {
        [styles.tableActionsCellFixed]: row.type === 'fixed',
        [styles.tableActionCellDragging]: draggableSnapshot?.isDragging,
      })}
      classes={{ root: styles.tableCell, body: styles.tableCellBody }}
      component="div"
    >
      {actions.map(
        ({ key, tooltipText, activeRow, icon, onClick, isHidden, color }) => {
          if (isHidden && isHidden(row)) {
            return null;
          }

          const handleClick = (event) => {
            event.stopPropagation();
            onClick(row);
          };

          if (tooltipText) {
            return (
              <Tooltip key={key} title={tooltipText}>
                <IconButton
                  className={clsx(styles.tableActionsCellButton, {
                    [styles.tableActionsCellButtonInvisible]:
                      row.visibility === 'invisible',
                    [styles.tableActionsCellButtonSecondary]:
                      color === 'secondary',
                    [styles.tableActionsCellButtonActive]:
                      activeRow?.id === row.id,
                  })}
                  onClick={handleClick}
                  size="small"
                  color={color}
                >
                  {icon(row)}
                </IconButton>
              </Tooltip>
            );
          }

          return (
            <div
              key={key}
              {...(key === 'burger' && {
                ...draggableProvidedProps?.dragHandleProps,
              })}
            >
              <IconButton
                key={key}
                onClick={handleClick}
                size="small"
                color={color}
              >
                {icon(row)}
              </IconButton>
            </div>
          );
        }
      )}
    </MaterialTableCell>
  );
};

export const tableActionModel = PropTypes.shape({
  icon: PropTypes.func,
  activeRow: PropTypes.objectOf(PropTypes.any),
  tooltipText: PropTypes.string,
  key: PropTypes.string,
  isHidden: PropTypes.func,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
});

DraggableActionsCell.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.arrayOf(tableActionModel),
  draggableSnapshot: PropTypes.objectOf(PropTypes.any),
  draggableProvidedProps: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  actionsClassName: PropTypes.string,
};

DraggableActionsCell.defaultProps = {
  row: [],
  actions: [],
  draggableSnapshot: null,
  draggableProvidedProps: null,
  actionsClassName: '',
};

export default DraggableActionsCell;
