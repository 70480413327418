export const MIN_REQUEST_TIME_MS = 500;

export function delay(ms = MIN_REQUEST_TIME_MS) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}

export async function copyToClipboard(text) {
  if (!navigator?.clipboard) {
    return false;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch {
    return false;
  }
}
